import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { titleize } from "inflected";

const LEFT_MARGIN = 40;
const TOP_MARGIN = 40;
const IMG_MAX_WIDTH = 500;
const FONT_SIZES = {
  title: 14,
  section: 12,
  text: 10,
};

const TABLES = [
  {
    title: "Detailed Results",
    headers: [
      "Category",
      "Input Description",
      "Weight",
      "Scenario Score",
      "Background Score",
      "Change",
    ],
    dataKey: "detailsData",
    rowMapper: (row) => [
      row.input_category,
      row.input_description,
      `${parseInt(row.wt * 100)}%`,
      row.scenario_risk,
      row.baseline_risk,
      row.delta,
    ],
  },
  {
    title: "Land Use",
    headers: [
      "Land Cover Type",
      "Modeled Current Conditions",
      "Selected Scenario",
      "Acres",
    ],
    dataKey: "detailsLandUseData",
    rowMapper: (row) => [
      row.use_new,
      row.background_landuse,
      row.scenario_landuse,
      row.acres,
    ],
  },
  {
    title: "Parcels",
    headers: [
      "Parcel Id",
      "Parcel Name",
      "Parcel Address",
      "Acres",
      "Cert Doc",
    ],
    dataKey: "detailsParcelsData",
    rowMapper: (row) => [
      row.parcel_id,
      row.parcel_owner,
      row.parcel_address,
      row.acres,
      row.cert_doc,
    ],
  },
];

const addText = (
  doc,
  text,
  x,
  y,
  fontSize = FONT_SIZES.text,
  align = "left"
) => {
  doc.setFontSize(fontSize);
  doc.text(text, x, y, { align });
};

const addSectionTitle = (doc, title, x, y) => {
  addText(doc, title, x, y, FONT_SIZES.section);
  return y + 15;
};

const addPlaceholder = (
  doc,
  x,
  y,
  message = "No results available for this section."
) => {
  addText(doc, message, x, y);
  return y + 30;
};

const addTable = (doc, x, y, headers, data, rowMapper) => {
  if (data && data.length > 0) {
    autoTable(doc, {
      startY: y,
      head: [headers],
      body: data.map(rowMapper),
      margin: { left: x, right: x },
      theme: "grid",
    });
    return doc.lastAutoTable.finalY + 30;
  }
  return addPlaceholder(doc, x, y);
};

export const generatePDF = ({ title, map, results, submittedPolygonArea }) => {
  if (!results || !map) return;

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "letter",
  });
  const pageWidth = doc.internal.pageSize.getWidth();
  let currentY = TOP_MARGIN;

  addText(doc, title, pageWidth / 2, currentY, FONT_SIZES.title, "center");
  currentY += 20;

  // Add Map Image
  const mapImage = map.getCanvas().toDataURL("image/png");
  const imgProps = doc.getImageProperties(mapImage);
  const imgWidth = IMG_MAX_WIDTH;
  const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
  doc.addImage(
    mapImage,
    "PNG",
    (pageWidth - imgWidth) / 2,
    currentY,
    imgWidth,
    imgHeight
  );
  currentY += imgHeight + 30;

  // Add Results Summary
  currentY = addSectionTitle(doc, "Results Summary", LEFT_MARGIN, currentY);

  if (results.data && results.data[0]) {
    const result = results.data[0];
    [
      `Scenario Score: ${result.user_scenario_score}`,
      `Background Score: ${result.user_background_score}`,
      `Change from Background: ${result.delta}`,
      `Query Area: ${submittedPolygonArea} acres`,
    ].forEach((text) => {
      addText(doc, text, LEFT_MARGIN, currentY);
      currentY += 15;
    });
    currentY += 15;
  } else {
    currentY = addPlaceholder(doc, LEFT_MARGIN, currentY);
  }

  // Add Tables
  TABLES.forEach(({ title, headers, dataKey, rowMapper }) => {
    currentY = addSectionTitle(doc, title, LEFT_MARGIN, currentY);
    currentY = addTable(
      doc,
      LEFT_MARGIN,
      currentY,
      headers,
      results[dataKey],
      rowMapper
    );
  });

  // Add Aquifer Vulnerability Section
  currentY = addSectionTitle(
    doc,
    "Aquifer Vulnerability",
    LEFT_MARGIN,
    currentY
  );
  if (results.detailsAqVulnData && results.detailsAqVulnData.length > 0) {
    results.detailsAqVulnData.forEach((row) => {
      addText(doc, `${titleize(row.intersection_info)}`, LEFT_MARGIN, currentY);
      currentY += 15;
      addText(
        doc,
        `${titleize(row.dtgw_heading)}: ${row.dtgw_values}`,
        LEFT_MARGIN,
        currentY
      );
      currentY += 15;
      addText(
        doc,
        `${titleize(row.clayth_heading)}: ${row.clayth_values}`,
        LEFT_MARGIN,
        currentY
      );
      currentY += 30;
    });
  } else {
    currentY = addPlaceholder(doc, LEFT_MARGIN, currentY);
  }

  // Save PDF
  doc.save(`${title.toLowerCase().replace(/ /g, "_")}.pdf`);
};
