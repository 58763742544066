import React, { useMemo, useState, useEffect } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import useProcessConfiguration from "../hooks/useProcessConfiguration";
import ResultsDialog from "./results/ResultsDialog";
import InfoDialog from "./InfoDialog";
import { POLYGON_MAX_ACREAGE } from "../constants";
import useBreakpoints from "../../../../../hooks/useBreakpoints";

const PolygonControls = ({
  isDrawing,
  polygon,
  startPolygonDraw,
  currentParams,
  drawnPolygonArea,
  map,
}) => {
  const { isSm } = useBreakpoints();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const buttonLabel = useMemo(() => {
    if (isDrawing) return "Cancel Draw";
    if (polygon) return "Draw New Polygon";
    return "Draw Polygon";
  }, [isDrawing, polygon]);

  const { mutation, submittedPolygonArea } = useProcessConfiguration();
  const {
    mutate: processConfiguration,
    isLoading: isProcessing,
    isError,
    error,
    data: resultsData,
  } = mutation;

  const handleProcessConfiguration = () => {
    processConfiguration({ paramsArray: currentParams, polygon });
  };

  useEffect(() => {
    if (resultsData) {
      setIsDialogOpen(true);
    }
  }, [resultsData]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Map Query Area
        </Typography>

        <InfoDialog
          title="How to Draw a Polygon"
          content={
            <>
              <ol>
                <li>
                  To draw a polygon, which will serve as your query area, click{" "}
                  <strong>"Draw Polygon"</strong>.
                </li>
                <li>
                  Click on the map to place the first point of your polygon.
                </li>
                <li>
                  Continue clicking to create additional points to define the
                  shape of your polygon.
                </li>
                <li>
                  Once you're done, click on the first point again to close the
                  polygon.
                </li>
                <li>
                  Alternatively, double-click on the final point to complete the
                  drawing.
                </li>
              </ol>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  <strong>Note: </strong>
                  {`Your polygon must be no more than ${POLYGON_MAX_ACREAGE}
                  acres. If it exceeds this limit, the scenario will not run.`}
                </Typography>
              </Box>
            </>
          }
        />
      </Box>

      <Box display="flex" flexDirection="column" gap={3} mt={2}>
        <Button
          onClick={startPolygonDraw}
          variant={isDrawing ? "outlined" : "contained"}
          color={isDrawing ? "primary" : "secondary"}
          fullWidth
          size={isSm ? "small" : "medium"}
        >
          {buttonLabel}
        </Button>

        <Divider sx={{ margin: [4, 3, 3, 3] }} />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Scenario Management
          </Typography>
          <InfoDialog
            title="Running a Scenario and Viewing Results"
            content={
              <>
                <Typography variant="body1">
                  Once you have drawn the polygon and selected your parameters,
                  you can proceed to run the scenario and view the results.
                </Typography>
                <ol>
                  <li>
                    Click <strong>"Run Scenario"</strong> to process your input
                    selections and the drawn polygon.
                  </li>
                  <li>
                    The system will process the query and generate results based
                    on your selections.
                  </li>
                  <li>
                    After processing, click{" "}
                    <strong>"View Most Recent Results"</strong> to see the
                    outcome.
                  </li>
                </ol>
                <Box mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Note:</strong> Viewing the results will always show
                    the most recently submitted scenario. Changes made to the
                    inputs or polygon after the scenario was submitted will not
                    be reflected in the displayed results.
                  </Typography>
                </Box>
              </>
            }
          />
        </Box>

        <Button
          onClick={handleProcessConfiguration}
          variant="contained"
          color="primary"
          fullWidth
          disabled={
            !polygon || isProcessing || drawnPolygonArea > POLYGON_MAX_ACREAGE
          }
          size={isSm ? "small" : "medium"}
        >
          {isProcessing ? "Processing..." : "Run Scenario"}
        </Button>

        {isError && (
          <Box mt={2}>
            <Typography color="error">
              Error processing configuration: {error.message}
            </Typography>
          </Box>
        )}

        {!isProcessing && resultsData && (
          <Button
            onClick={() => setIsDialogOpen(true)}
            variant="contained"
            color="secondary"
            fullWidth
          >
            View Most Recent Results
          </Button>
        )}
      </Box>

      {resultsData && (
        <ResultsDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          results={resultsData}
          submittedPolygonArea={submittedPolygonArea}
          map={map}
        />
      )}
    </>
  );
};

export default PolygonControls;
